import i18n from "i18next";

import English from "./locales/English.json";
import Russian from "./locales/Russian.json";
import IngameNames from "./locales/game.json";

import { initReactI18next } from "react-i18next";

const resources = {
    ChineseSimplified: {
        game: IngameNames.ChineseSimplified,
    },
    ChineseTraditional: {
        game: IngameNames.ChineseTraditional,
    },
    English: {
        translation: English,
        game: IngameNames.English,
    },
    French: {
        game: IngameNames.French,
    },
    German: {
        game: IngameNames.German,
    },
    Indonesian: {
        game: IngameNames.Indonesian,
    },
    Japanese: {
        game: IngameNames.Japanese,
    },
    Korean: {
        game: IngameNames.Korean,
    },
    Portuguese: {
        game: IngameNames.Portuguese,
    },
    Russian: {
        translation: Russian,
        game: IngameNames.Russian,
    },
    Spanish: {
        game: IngameNames.Spanish,
    },
    Thai: {
        game: IngameNames.Thai,
    },
    Vietnamese: {
        game: IngameNames.Vietnamese,
    },
    Italian: {
        game: IngameNames.Italian,
    },
    Turkish: {
        game: IngameNames.Turkish,
    },
};

export const languages = Object.keys(resources);

i18n.use(initReactI18next)
    .init({
        resources,
        defaultNS: "translation",
        lng: "English",
        fallbackLng: "English",
        debug: false,
        interpolation: {
            escapeValue: false,
        },
    });