import { AbyssCard } from "./types"

export const defaultJson: AbyssCard = {
    lang: "English",
    date: "",
    floor: 0,
    background: "spire",

    missions: [],
    groups: [],
    rooms: []
};