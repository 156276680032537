import React from 'react';

import './i18n';

import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import Home from './components/home';

const darkTheme = createTheme({
  palette: { mode: 'dark' },
});

function App() {
  return (
    <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <Home />
        {/* <div id="app">
            <Router>
                <Route path="/" component={Home} />
            </Router>
        </div> */}
    </ThemeProvider>
  );
}

export default App;
