import React, { Suspense } from 'react';

import Split from 'react-split';
import './style.css'

import { defaultJson } from '../../abyss';
import { AbyssCard } from '../../abyss/types';

const Settings = React.lazy(() => import('../settings'));
const Card = React.lazy(() => import('../card'));

type HomeState = { json: AbyssCard; };
class Home extends React.PureComponent<{}, HomeState> {
    state = { json: defaultJson };

    render() {
        return <Suspense fallback={<div>Loading...</div>}>
            <Split className="split">
                <Settings json={this.state.json} onEdit={(val) => this.setState({ json: val })} />
                <Card json={this.state.json} />
            </Split>
        </Suspense>;
    }
}

export default Home;